import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionRulesMaybe.module.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, publicData } = props;
  const classes = classNames(rootClassName || css.root, className);

  const availableFrom = publicData.availableFrom?.date
    ? new Date(publicData.availableFrom.date).toLocaleDateString()
    : publicData.availableFrom
    ? new Date(publicData.availableFrom).toLocaleDateString()
    : null;

  const availableUntil = publicData.availableUntil?.date
    ? new Date(publicData.availableUntil.date).toLocaleDateString()
    : publicData.availableUntil
    ? new Date(publicData.availableUntil).toLocaleDateString()
    : null;

  const isOffering = publicData?.category === 'offeringPlace';
  const language = publicData.language;
  const capacity = publicData.capacityNumberOfPeople;
  const capacityDescription = publicData.capacityDescription;
  const pets = publicData.pets && publicData.pets === 'yes';
  const petsNotAllowed = publicData.pets && publicData.pets === 'no';
  const petsInfo = publicData.petsInfo;
  const accessibility =
    Array.isArray(publicData.accessibility) && publicData.accessibility[0] === 'yes';
  const accessibilityInfo = publicData.accessibilityInfo;
  const noAccessibility =
    Array.isArray(publicData.accessibility) && publicData.accessibility[0] === 'no';

  const assistanceAnimals =
    Array.isArray(publicData.assistanceAnimals) && publicData.assistanceAnimals[0] === 'yes';
  const assistanceAnimalsNotAllowed =
    Array.isArray(publicData.assistanceAnimals) && publicData.assistanceAnimals[0] === 'no';
  const assistanceAnimalsInfo = publicData.assistanceAnimalsInfo;

  const allergies = publicData.allergies && publicData.allergies === 'yes';
  const allergiesInfo = publicData.allergiesInfo;

  return availableFrom || language || capacity ? (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      {availableFrom ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.datePickAvailableFrom" />
          </strong>{' '}
          {availableFrom}
        </p>
      ) : null}

      {availableUntil ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.datePickAvailableUntill" />
          </strong>{' '}
          {availableUntil}
        </p>
      ) : null}

      {language ? (
        <p>
          <strong>
            {isOffering ? (
              <FormattedMessage id="ListingPage.languageOffer" />
            ) : (
              <FormattedMessage id="ListingPage.languageRequest" />
            )}
          </strong>{' '}
          {language}
        </p>
      ) : null}

      {capacity ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.capacityNumberOfPeople" />
          </strong>{' '}
          {capacity}
        </p>
      ) : null}

      {capacityDescription ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.capacityDescriptionOffer" />
          </strong>
          <br />
          {capacityDescription}
        </p>
      ) : null}

      {pets && petsInfo ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.petsPresent" />
          </strong>{' '}
          {petsInfo}
        </p>
      ) : null}

      {petsNotAllowed && (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.petsNotPresent" />
          </strong>
        </p>
      )}

      {accessibility && accessibilityInfo ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.accessibilityTrue" />
          </strong>{' '}
          {accessibilityInfo}
        </p>
      ) : null}

      {noAccessibility && (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.accessibilityFalse" />
          </strong>
        </p>
      )}

      {assistanceAnimals && assistanceAnimalsInfo ? (
        <p>
          <strong>
            {isOffering ? (
              <FormattedMessage id="ListingPage.assistanceAnimalsOffer" />
            ) : (
              <FormattedMessage id="ListingPage.assistanceAnimalsRequest" />
            )}
          </strong>
          <br />
          {assistanceAnimalsInfo}
        </p>
      ) : null}

      {assistanceAnimalsNotAllowed && (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.assistanceAnimalsNotAllowed" />
          </strong>
        </p>
      )}

      {allergies && allergiesInfo ? (
        <p>
          <strong>
            <FormattedMessage id="ListingPage.allergies" />
          </strong>
          <br />
          {allergiesInfo}
        </p>
      ) : null}

      {/* <p className={css.rules}>{publicData.rules}</p> */}
    </div>
  ) : null;

  // return publicData && publicData.rules ? (
  //   <div className={classes}>
  //     <h2 className={css.title}>
  //       <FormattedMessage id="ListingPage.rulesTitle" />
  //     </h2>
  //     <p className={css.rules}>{publicData.rules}</p>
  //   </div>
  // ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    rules: string,
  }),
};

export default SectionRulesMaybe;
